import React, { useEffect, useState, Fragment } from "react"; 
import { useBreakpoint } from "gatsby-plugin-breakpoints"; 

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { domain, formatRichText, isMobile, stripString } from '../utils';
import Markdown from "markdown-to-jsx"
import CardArticle from "../components/card-article"
import { FormattedMessage } from "react-intl";
import Header from '../components/header';
import Collapse from '../components/wrappers/collapse';
import iconPlus from "../images/plus-white.svg";
import classNames from "classnames"
import { graphql } from 'gatsby';
import Media from "../components/media";
import Carousel from "../components/wrappers/carousel";
import Testimonial from "../components/testimonial";
import Cta from "../components/cta";
import CaseListCarousel from "../components/case-list-carousel";
import Link from "../components/link";
import { useContext } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import CaseList from "../components/case-list";
import { CustomHr } from "../components/CustomHr"

// TODO - Break out sections into components (if useful) and put in content

const CaseDetail = (props) => {
  const [isOpen, setIsOpen] = useState({}); 
  const { footers, general, headers, caseStudy } = props.data.strapi; 
  const { copyright } = caseStudy;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode  , fontColorDarkMode, fontColorLightMode, introSectionBgColorDarkMode, introSectionBgColorLightMode, ctaEnglish, ctaGerman, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const { clientLogo } = caseStudy; 
  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const headerFontColor = caseStudy.headerFontColor; 
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const svgUrl = state.svgUrl;

  const darkMode = state.darkModePreference === 'moon'; 
  const {isPageTransitionActive} = state.isPageTransition; 
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = caseStudy.typeLink && (caseStudy.typeLink.label + " " + caseStudy.title ); 

  const seoTitle = caseStudy && caseStudy.seo && caseStudy.seo.metaData.metaTitle || pageFirstHeading|| "case details";
  const seoDescription = caseStudy && caseStudy.seo && caseStudy.seo.metaData.metaDescription || pageFirstHeading|| " case details ";
  const seoKeywords = caseStudy && caseStudy.seo && caseStudy.seo?.metaData?.keywords;
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices; 
  const {case_study} = caseStudy; 
  const pageRedirectInfo = case_study; 
  const filteredrelatedCards = caseStudy.articles?.relatedCard.filter((relatedCard) => !relatedCard?.case_study?.inactive)
  const redirectTo = pageRedirectInfo?.language && pageRedirectInfo?.slug ? `/${pageRedirectInfo?.language}/case-studies/${pageRedirectInfo?.slug}` : ""; 
  const [anchorLinks, setAnchorLinks] = useState([])
  const newAnchorLinks = []; 
  const showBlackNav = headerFontColor === 'black'; 
  const isSubOpen = state.siteSubNav;
  const primaryBtn = general?.primaryBtn;
  const showX = {path: `/${language}/case-studies/`}; 
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const caseIntroBgColor = darkMode ? introSectionBgColorDarkMode : introSectionBgColorLightMode; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const cta = language == "en" ? ctaEnglish : ctaGerman; 
  const breakpoints = useBreakpoint(); 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  const toggleIsOpen = (id) => {
    setIsOpen({
      ...isOpen, 
      [id]: !isOpen[id]
    })
  }

  useEffect(() => {
   dispatch({ type: "SET_SCROLL_TO_SECTION" , payload: {
      id: stripString(caseStudy && caseStudy.title)
    }});    
   
    caseStudy.main && caseStudy.main.forEach((component) => {
      if (component.__typename === "STRAPI_ComponentComplexVerticalBodyImage") {
        const anchorText = component && component?.header?.anchorText; 
        anchorText && newAnchorLinks.push({header: anchorText , display: true}); 
      } 
      if (component.__typename === 'STRAPI_ComponentComplexCaptureGallery' || component.__typename === 'STRAPI_ComponentComplexBodyLink' || component.__typename === 'STRAPI_ComponentPlainCases') {
        const anchorText = component &&  component?.anchorText; 
        anchorText && newAnchorLinks.push({header: anchorText , display: true}); 
      }
      if (component.__typename === "STRAPI_ComponentComplexHorizontalBodyImage") {
        const anchorText = component &&  component?.horizontalBodyImage?.anchorText; 
        anchorText && newAnchorLinks.push({header: anchorText , display: true}); 
      }
    })
    setAnchorLinks(newAnchorLinks);

    if (state.isPageTransition.isPageTransitionActive) {
      dispatch({ type: "TOGGLE_SITE_PAGE_TRANSITION"});
      
      setTimeout(() => {
        dispatch({type: 'SET_SVG_URL', payload: ""}); 
      }, 1000)
    }
    
    const id = "case-studies"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
    return () => {
        dispatch({ 
        type: "RESET_SCROLL_TO_SECTION" , 
      });
    }
  }, []); 
  
  return (
    <Layout header={header} footer={footer} language={caseStudy.language} redirectTo={redirectTo} anchorLinks={anchorLinks} showX={showX} clientLogo={clientLogo} logosBgColor={logosBgColor} showBlackNav={showBlackNav} >
      <SEO
        websiteFontColor={websiteFontColor} 
        template="case-detail" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={caseStudy.darkTheme} 
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />

        <Hero 
          heroEntries={[{ case_study: caseStudy, linearGradient: false }]} 
          showLink={false} 
          headerFontColor={caseStudy.headerFontColor} 
          tags={caseStudy?.tags}
          hashtagsBackgroundColor={displayedHashtagBgColor}
          borderRadius={imageBorderRadiusForServices}
          showclientLogo={isPageTransitionActive && svgUrl}
          logosBgColor={logosBgColor}
        />

      <SiteHeaderAlt header={header} redirectTo={redirectTo} anchorLinks={anchorLinks} showX={showX} showBlackNav={showBlackNav}>
        <section className={`case-overview`} style={{ backgroundColor: caseIntroBgColor || caseStudy.backgroundColor }}>
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="case-overview__teaser">
                  <ul className="case-overview__directory directory row no-gutters">
                    <li className="directory__group col-4 col-md-12">
                      <dt className="directory__title"  style={{color: websiteFontColor }} ><FormattedMessage id="basic.type" defaultMessage="Type"/></dt>
                      <dd className="directory__value"><a href={caseStudy.typeLink && caseStudy.typeLink.path}>{ caseStudy.typeLink && caseStudy.typeLink.label }</a></dd>
                    </li>
                    <li className="directory__group col-4 col-md-12">
                      <dt className="directory__title" style={{color: websiteFontColor }} ><FormattedMessage id="basic.branch" defaultMessage="Branch"/></dt>
                      <dd className="directory__value"><a href={caseStudy.branchLink && caseStudy.branchLink.path}>{ caseStudy.branchLink && caseStudy.branchLink.label }</a></dd>
                    </li>
                    <li className="directory__group col-4 col-md-12">
                      <dt className="directory__title"  style={{color: websiteFontColor }} ><FormattedMessage id="basic.projectTechnologies" defaultMessage="Project Period"/></dt>
                      <dd className="directory__value">{ caseStudy.projectTechnologiesValue }</dd>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="case-overview__teaser case-overview__lead mb-0">
                  <p>{caseStudy.description }</p>
                </div>
              </div>
            </div>
            <div className="row">
              {caseStudy.overview && caseStudy.overview.map((component, index) => {
                if (component.__typename === "STRAPI_ComponentPlainTextBlock") {
                  return (
                    <div className="col-md-4">
                      <div className="case-overview__teaser">
                        <h3 
                          className={classNames("case-overview__heading", {
                            open: isOpen[component.id],
                          })}
                          onClick={() => toggleIsOpen(component.id)}
                        >
                          {component.heading}
                          <img 
                            className="case-overview__icon" 
                            src={iconPlus} loading="lazy"
                            style={{borderRadius: imageBorderRadiusForServices}}
                          />
                        </h3>
                        <Collapse isOpen={isOpen[component.id]}>
                          <p>{component.description}</p>
                        </Collapse>
                      </div>
                    </div>
                  )
                }

                if (component.__typename === "STRAPI_ComponentComplexList") {
                  return (
                    <div className="col-md-4">
                      <div className="case-overview__teaser">
                        <h3 
                          onClick={() => toggleIsOpen(component.id)}
                          className={classNames("case-overview__heading", {
                            open: isOpen[component.id],
                          })}
                        >
                          {component.title}
                          <img 
                            className="case-overview__icon" 
                            src={iconPlus} loading="lazy" 
                            style={{borderRadius: imageBorderRadiusForServices}}
                          />
                        </h3>
                        <Collapse isOpen={isOpen[component.id]}>
                          <ul className="list list-border list-arrowed">
                            {component.text.map((item, index) => {
                              if (!item.description) { 
                                item.description = item.heading
                                delete item.heading;
                              }

                              return (
                                <li key={index} className="list-border__item">
                                  {item.heading && <span className="list-border__title">{item.heading}</span>}
                                  {item.description}
                                </li>
                              )
                            })}
                          </ul>
                        </Collapse>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </section>
        <section className="case-detail section">
          {caseStudy.main && caseStudy.main.map((component, index) => {
            if (component.__typename === "STRAPI_ComponentPlainCases" && component?.casesSlider?.caseStudies?.length > 0) {
              const anchorText = component.anchorText ||''; 
              const caseStudies = component.casesSlider.caseStudies; 

               return ( 
                  <div 
                    className={`section cases ${anchorText ? 'anchor-text' : ''}`} 
                    id={stripString(anchorText ||'')}
                  >
                    {component.heading && (
                      <Header 
                        heading={component?.heading} 
                      />
                    )}
                     <div className="container">
                        <div className="row">
                          <div className="offset-lg-1 col-lg-10">
                            <CaseList
                              caseStudies={caseStudies} 
                              oddElementMarginTop={true}  
                              showCaseIntroText={true}
                              websiteFontColor={websiteFontColor}
                              imageBorderRadiusForServices={imageBorderRadiusForServices}
                              hashtagsBackgroundColor={displayedHashtagBgColor}
                              pageTransitionBgColor={pageTransitionBgColor}
                            />
                          </div>
                        </div>
                     </div>
                  </div>
              )
            }
            if (component.__typename === "STRAPI_ComponentPlainMedia") {

              return (
                <div className="case-detail__content">
                  <Media 
                    media={component.media} 
                    width={component.width} 
                    positionVideoCenter={true}
                    imageBorderRadiusForServices={component.width !== 'fullWidth' && imageBorderRadiusForServices}
                  />
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexHorizontalBodyImage") {
              const rows = component.horizontalBodyImage;

              return rows.map((row, index) => {

                const isOdd = index % 2;
                const bodySide = row.bodySide || (isOdd ? 'right' : 'left');
                const showImageCarousel = row.media && row.media.filter(media => media.url)?.length > 1 ; 

                const body = (
                  <div className={classNames("col-md-4 case-detail__body rich-text", {"rich-text--no-arrow": row.hideArrows})}>
                    {row.richText && (
                      <Markdown 
                        options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}
                      >
                        {formatRichText(row.richText || '')}
                      </Markdown>
                    )}
                  </div>
                )
                const media = (
                  <div className="col-md-8 case-detail__media-gapped">
                    {row.media && row.media.length > 0 && (
                      <Fragment>
                        {(showImageCarousel) && (
                         <div className={row.horizontalMediaType == "video" ? "image--fluid" : "image--fluid"}>
                            <Carousel>
                              {row.media.map((media, index) => {
                                return (
                                  <Media 
                                    media={media} 
                                    className="image--fluid" 
                                    width={'semiMedium' ||media.width} 
                                    withCaption={false} 
                                    imageBorderRadiusForServices={row.media.width !== 'fullWidth' && imageBorderRadiusForServices}
                                  /> 
                                 )
                              })}
                            </Carousel>
                          </div>
                        )}
                        {!showImageCarousel && (
                          <div className={row.horizontalMediaType == "video" ? "video-wrapper" : ""}>
                            <Media 
                              media={row.media[0]} 
                              className="image--fluid" 
                              width={row.horizontalMediaType == "video" ? "large" : ""}
                              withCaption={false} 
                              addPaddingToVideoWrapper={true}
                              imageBorderRadiusForServices={row.media[0].width !== 'fullWidth' && imageBorderRadiusForServices}
                              showVideoWrapper={row.horizontalMediaType === "video"}
                            /> 
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                )
                const anchorText = component &&  component.horizontalBodyImage.anchorText; 
                return (
                  <div className={`case-detail__content case-detail__content--${bodySide} ${anchorText ? 'anchor-text': ''} `} id={stripString(anchorText || '')}>
                     {row?.heading && (
                       <Header heading={row.heading}  withFullWidth={true}/>
                     )}          
                    <div className="container">
                      <div className="row align-items-center">
                        {bodySide === 'left' ? body : media}
                        {bodySide === 'right' ? body : media}
                      </div>
                    </div>
                  </div>
                )
              });
            }

            if (component.__typename === "STRAPI_ComponentComplexCaptureGallery") {
              const medias = component.medias;
              const anchorText = component &&  component.anchorText; 
              return (
                <div className={`case-detail__content ${anchorText ? 'anchor-text': ''} `} id={stripString(anchorText || '')}>
                  <div className="container">
                    {component.heading && (
                      <div className="case-detail__header row">
                        <div className="col-md-5 offset-md-1">
                          <h2 className="case-detail__heading">{component.heading}</h2>
                        </div>
                      </div>
                    )}
                    {component.richText && (
                      <div className="case-detail__body row">
                        <div className={classNames("col-md-7 offset-md-2 rich-text", {"rich-text--no-arrow": component.hideArrows})}>
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {formatRichText(component.richText || '')}</Markdown>
                        </div>
                      </div>
                    )}
                    {medias && medias.length > 0 && medias.length === 1 &&  (
                      <div className="case-detail__gallery row">
                        {medias.map((media, index) => {
                          return ( 
                            <Media 
                              media={media.media} 
                              width={media.width} 
                              columns={media.columnsOutOf12} 
                              className="case-detail__image case-detail__image--small mb-0" 
                              captionPosition={component.captionPosition} 
                              withCaption={true} 
                              imageBorderRadiusForServices={media.width !== 'fullWidth' && imageBorderRadiusForServices}
                            />
                          )
                        })}
                      </div>
                    )}
                    {(medias && medias.length > 1 && (!breakpoints.sm)) && (
                      <div className="case-detail__gallery row">
                        {medias.filter(media => media?.media).map((media, index) => {
                          if (!media?.media) return <></>
                          return ( 
                            <Media 
                              media={media.media} 
                              width={media.width} 
                              columns={media.columnsOutOf12} 
                              className="case-detail__image case-detail__image--small mb-0" 
                              captionPosition={component.captionPosition} 
                              withCaption={true} 
                              imageBorderRadiusForServices={media.width !== 'fullWidth' && imageBorderRadiusForServices}
                            />)
                        })}
                      </div>
                    )}
                    {(medias && medias.length > 1 && (breakpoints.sm)) && (
                      <div className="row card-articles case-detail__gallery">
                        {medias.filter(media => media?.media).map((media, index) => {
                          if (!media?.media) return <></> 

                          return (
                             <CardArticle 
                               media={media.media} 
                               isCaseDetailPage={true} 
                               imageBorderRadiusForServices={imageBorderRadiusForServices} 
                               hashtagsBackgroundColor={displayedHashtagBgColor}
                               sectionBackgroundColor={sectionBackgroundColor}
                            />
                          )}
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexVerticalBodyImage") {
              const columns = component.verticalBodyImage;
              const colSize = columns.length === 1 ? "col-md-8 offset-md-2" : "col-md";
              const anchorText = component && component?.header?.anchorText; 

              return (
                <div className={`case-detail__content ${anchorText ? 'anchor-text': ''}`} id={stripString(anchorText || '')}>
                  <div className="container">
                    <div className="row">
                      {columns.map((column, index) => {
                        return (
                          <div className={colSize}>
                            {column.media && column.media.length > 0 && (
                              <Fragment>
                                {column.media.length > 1 ? (
                                  <Carousel>
                                    {column.media.map((media, index) => {
                                      return (
                                        <img 
                                          className="case-detail__image image--fluid case-detail__image--small mb-3"
                                          src={domain+(media.url)} 
                                          alt={media.alternativeText} 
                                          style={{borderRadius: imageBorderRadiusForServices}} 
                                        />
                                      )
                                    })}
                                  </Carousel>
                                ) : (
                                  <img 
                                    className="case-detail__image image--fluid case-detail__image--small mb-3"
                                    src={domain+(column.media[0].url)} 
                                    alt={column.media[0].alternativeText}  
                                    loading="lazy"
                                    style={{borderRadius: imageBorderRadiusForServices}} 
                                   />
                                )}
                              </Fragment>
                            )}
                            <div className={classNames(`case-detail__body rich-text`, {"rich-text--no-arrow": column.hideArrows})}>
                              {column.richText && (
                                <Markdown 
                                  options={{
                                    overrides: {a: {
                                      component: Link
                                    },                           
                                    hr: { 
                                        component: CustomHr,
                                        props: {
                                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                        }
                                      }  
                                    }
                                  }}
                                >
                                  {formatRichText(column.richText || '')}
                                </Markdown>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexBodyLink") {
              return (
                <Cta 
                  component={cta || component} 
                  fontColorLightMode={websiteFontColor} 
                  anchorId={component.anchorText || ''} 
                  greySectionBgColor={greySectionBgColor}
                  className={'pt-0 pb-0'}
                />
              )
            }

            if (component.__typename === "STRAPI_ComponentComplexTestimonials"  && component?.testimonials?.length && component.showTestimonials) {
              return (
                <div className="case-detail__content">
                  <div className="container">
                    <Testimonial 
                      testimonialBtnText={primaryBtn}
                      testimonials={component.testimonialsrandomIndex} 
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      logosBgColor={logosBgColor}
                      sectionBackgroundColor={sectionBackgroundColor}
                    />
                  </div>

                </div>
              )
            }

            return <></>
          })}
        </section>

        {caseStudy.articles && caseStudy.articles?.relatedCard.length > 0 && (
          <section className="section case-related cards-articles">
            <Header heading={caseStudy.articles.heading}  withFullWidth={true}/>
            <div className="container">
              <div className="row card-articles">
                {filteredrelatedCards && filteredrelatedCards.map((relatedCard, index) => {
                  const article = relatedCard.blog || relatedCard.case_study;
                  return (
                    <CardArticle 
                      article={article} 
                      path={relatedCard.blog ? 'blog' : 'case-studies'} 
                      showMobileImage={false} 
                      isCasesPage={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                      sectionBackgroundColor={sectionBackgroundColor}
                    />
                  )
                })}
              </div>
            </div>
          </section>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export default CaseDetail

export const pageQuery = graphql`
  query caseDetail($id: ID!, $language: String!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        clientLogosColorLightMode
        clientLogosColorDarkMode
        greySectionLightModeBgColor
        imageBorderRadiusForServices
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        greySectionDarkModeBgColor
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
        introSectionBgColorDarkMode
        introSectionBgColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        ctaEnglish {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        ctaGerman {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
      }
      caseStudy(id: $id) {
        copyright
        darkTheme
        headerFontColor
        clientLogo {
          logo {
            url
          }
        }
        tags {
          tag
          active
        }
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        case_study {
          slug
          language
        }
        language
        headerFontColor
        slug
        date
        title
        headerImage {
          url
        }
        headerImageMobile {
          url
        }
        supLink {
          label
          path
        }
        showSliderBtn
        typeLink {
          label
          path
        }
        branchLink {
          label
          path
        }
        projectTechnologiesValue
        description
        hideArrows
        backgroundColor
        overview {
          ... on STRAPI_ComponentPlainTextBlock {
            id
            __typename
            heading
            description
          }
          ... on STRAPI_ComponentComplexList {
            id
            __typename
            title
            text {
              heading
              description
            }
          }

        }
        main {
         ... on STRAPI_ComponentPlainCases {   
            __typename  
            heading
            anchorText  
            casesSlider {
              heading 
              anchorText
              caseStudies {
               ...CardArticleCaseStudy
              }
            }
          }
          ... on STRAPI_ComponentPlainMedia {
            __typename
            media {
              url
              ext
              mime
              alternativeText
            }
            width
          }
          ... on STRAPI_ComponentComplexHorizontalBodyImage {
            __typename
            horizontalBodyImage {
              heading
              media {
                url
                alternativeText
                mime
                ext
              }
              richText
              hideArrows
              bodySide
              horizontalMediaType
              anchorText
            }
          }
          ... on STRAPI_ComponentComplexCaptureGallery {
            __typename
            medias {
              media {
                url
                alternativeText
                mime
                caption
                ext
              }
              width
              columnsOutOf12
            }
            heading
            richText
            hideArrows
            captionPosition
            anchorText
          }
          ... on STRAPI_ComponentComplexVerticalBodyImage {
            __typename
            verticalBodyImage {
              media {
                url
                alternativeText
                mime
                ext
              }
              richText
              hideArrows
            }
            header {
              anchorText
            }
          }
          ... on STRAPI_ComponentComplexBodyLink {
            __typename
            text {
              heading
              description
            }
            link {
              label
              path
            }
            anchorText
          }
          ... on STRAPI_ComponentComplexTestimonials {
            __typename
            testimonials {
              media {
                url
                alternativeText
                mime
                ext
              }
              quote
              description
              name
              position
              company
              logo {
                url
                alternativeText
                mime
                ext
              }
            }
          }
        }
        articles {
          sup
          heading
          relatedCard {
            blog {
              ...CardArticleBlog
            }
            case_study {
              ...CardArticleCaseStudy
            }
          }
          anchorText
        }
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
